import { Component, Vue } from 'vue-property-decorator';
import { Form as ElForm, Input } from 'element-ui';
import { authService } from '@/modules/account/services/authService';
import { accountServices } from '../services/accountService';

@Component({
  name: 'RecoverPassword'
})
export default class extends Vue {
  private recoverForm: account.requireResetPassword = { userName: null };

  get recoverRules() {
    return {
      userName: [
        {
          required: true,
          message: `${this.$t('recoverPassword.required.username')}`,
          trigger: 'blur'
        },
        {
          validator: (rule, value, callback) => this.checkUsernameExist(rule, value, callback),
          trigger: 'blur'
        }
      ]
    }
  }

  private checkUsernameExist(rule, value, callback) {
    if (!value) {
      return callback(new Error(`${this.$t('recoverPassword.required.usernameRequired')}`));
    }
    accountServices.CheckUsernameExist(value).then((result: boolean) => {
      if (result) {
        callback();
      } else {
        return callback(new Error(`${this.$t('recoverPassword.noUserFound')}`));
      }
    });
  }

  private loading = false;
  mounted() {
    if (this.recoverForm.userName === '') {
      (this.$refs.userName as Input).focus();
    }
  }

  private handleRecover() {
    (this.$refs.recoverForm as ElForm).validate(async(valid: boolean) => {
      if (valid) {
        this.loading = true;
        accountServices
          .RequireResetPassword(this.recoverForm)
          .then(() => {
            this.$message({
              message: `<strong>${this.$t('recoverPassword.success')}</strong><br>${this.$t('recoverPassword.successRedirect')}`,
              showClose: true,
              dangerouslyUseHTMLString: true,
              type: 'success',
              duration: 5000
            });
            this.$router.push({ path: 'login' });
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.$message({
              message: `${this.$t('recoverPassword.error')}`,
              showClose: true,
              type: 'error'
            });
          });
      }
    });
  }

  public backToLogin() {
    this.$router.push({ path: '/login' });
  }
}
