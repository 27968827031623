import { render, staticRenderFns } from "./recoverPassword.vue?vue&type=template&id=61be15e4"
import script from "./recoverPassword.ts?vue&type=script&lang=ts&external"
export * from "./recoverPassword.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports