import settings from "@/settings";
import { baseRestService } from "@/services/_base/baseRestService";

class AccountServices extends baseRestService {

  constructor() {
    super();
    this.baseUrl = () => settings.authSettings.endpoint;
  }

  async CheckUsernameExist(username): Promise<boolean> {
    return await this.Get<boolean>('/identity/auth/check/username/' + username);
  }

  async CheckEmailExist(email): Promise<boolean> {
    return await this.Get<boolean>('/identity/auth/check/email/' + email);
  }

  async Register(obj: account.register): Promise<user.item> {
    const returnUrl = `${location.origin}/emailConfirm`;
    return this.Post('/identity/auth/register', obj, { returnUrl });
  }

  async RequireConfirmEmail(obj: account.forgotPassword): Promise<any> {
    const returnUrl = `${location.origin}/emailConfirm`;
    return this.Post('/identity/auth/email/confirm', obj, { returnUrl });
  }

  async ConfirmEmail(userId: string, code: string): Promise<any> {
    const returnUrl = `${location.origin}`;
    return this.Get('/identity/auth/email/confirm', { userId, code, returnUrl });
  }

  async SetPassword(obj: account.setPassword): Promise<any> {
    return this.Post('/identity/auth/password/set', obj);
  }

  async ChangePassword(obj: account.changePassword): Promise<any> {
    return this.Post('/identity/auth/password/change', obj);
  }

  async RequireResetPassword(obj: account.requireResetPassword): Promise<any> {
    const returnUrl = `${location.origin}/resetPassword`;
    return this.Post('/identity/auth/password/reset-required', obj, { returnUrl });
  }

  async ResetPassword(userId: string, code: string, password: string): Promise<any> {
    return this.Post('/identity/auth/password/reset', {
      userId,
      code,
      newPassword: password
    });
  }

  async AddPassword(userId: string, code: string, password: string): Promise<any> {
    return this.Get('/identity/auth/password/add', {
      userId,
      code,
      newPassword: password
    });
  }

  async GenerateRandomPassword(): Promise<string> {
    return this.Get<string>('/identity/auth/password/generate-random');
  }
}

export const accountServices = new AccountServices();
